import {TRANSLATION_NL} from 'src/environments/wave/translations/nl';
import {TRANSLATION_EN} from 'src/environments/wave/translations/en';
import {TRANSLATION_DE} from 'src/environments/wave/translations/de';
import {TRANSLATION_TR} from 'src/environments/wave/translations/tr';
import {TRANSLATION_PL} from 'src/environments/wave/translations/pl';
import {TRANSLATION_RO} from 'src/environments/wave/translations/ro';
import {TRANSLATION_PT} from 'src/environments/wave/translations/pt';

export const environment = {
    production: true,
    id: 'com.volkerwessels.wave',
    version: '4.7.0',
    name: 'WAVE',
    author: {
        name: 'Koninklijke VolkerWessels N.V.',
        email: 'info@volkerwessels.com',
        website: 'https://www.volkerwessels.com/',
    },
    deprecation: {
        domains: [],
        date: '2099-09-01T00:00:00',
    },
    host: 'vwwave',
    storage: '__wave',
    initialTheme: '',
    color: {
        primary: '#32659C',
        statusBar: {
            default: '#336699',
            overlay: '#14293D',
        },
    },
    supportedLanguages: [
        {
            value: 'nl',
            label: 'Nederlands',
            translationFile: TRANSLATION_NL,
        },
        {
            value: 'de',
            label: 'Deutsch',
            translationFile: TRANSLATION_DE,
        },
        {
            value: 'en',
            label: 'English',
            translationFile: TRANSLATION_EN,
        },
        {
            value: 'tr',
            label: 'Türkçe',
            translationFile: TRANSLATION_TR,
        },
        {
            value: 'pl',
            label: 'Polsku',
            translationFile: TRANSLATION_PL,
        },
        {
            value: 'ro',
            label: 'Romana',
            translationFile: TRANSLATION_RO,
        },
        {
            value: 'pt',
            label: 'Portugues',
            translationFile: TRANSLATION_PT,
        },
    ],
    api: 'https://www.volkerwesselswave.nl',
    apiTimeout: 60000,
    msAuth: {
        tenantId: 'common',
        clientId: '55208627-79e9-470d-b96e-39084973f880',
        keystoreHash: '5jVLQOpodg8NwJRf91vH7f791hM=',
        scopes: ['api://55208627-79e9-470d-b96e-39084973f880/default'],
        redirectUri: 'https://app.volkerwesselswave.nl',
    },
    deeplinks: [
        'https://www.volkerwesselswave.nl/deeplink/',
        'https://volkerwesselswave.nl/deeplink/',
        'https://app.volkerwesselswave.nl/deeplink/',
    ],
    whitelistedDomains: [
        'www.volkerwesselswave.nl',
        'volkerwesselswave.nl',
        'app.volkerwesselswave.nl',
    ],
    privacyPolicyUrl: 'https://www.volkerwessels.com/privacyverklaring_WAVE-app',
    instructionFilmUrl: 'https://www.volkerwessels.com/veiligheid/wave_app',
    applicationInsights: {
        instrumentationKey: '0e60aa15-c020-4e10-beb0-75193657fc95',
    },
};

